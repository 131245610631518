@import "../../styles/setting.module";

.HeaderContainer {
  position: fixed;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 90;
  border-bottom: 2px solid $ci-very-dark-grey;
  border-top: 2px solid $ci-very-dark-grey;
}

.Header {
  height: 60px;
  width: 100%;
  max-width: $screen-max-width;
  background-color: $ci-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  gap: 1rem;
}

.Header__title {
  font-size: 1.6rem;
  font-weight: 600;
  color: $ci-very-dark-grey;
}

.Header__logout {
  white-space: nowrap;
  line-height: 1.5rem;

  a {
    color: $ci-white-bg;
  }
}

@media only screen and (min-width: $laptop-and-above-breakpoint) {
  // .Header__title {
  //   font-size: 2rem;
  // }
}