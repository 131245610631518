@import "./setting.module";
@import "./tools.module";

.Listing {
  padding: 10px;
  // @include border(5px, 0, $ci-lightblue-bg);
  border-bottom: 0px;
  min-height: 200px;

  &__title {
    margin-top: 0;
  }

  &__link {
    text-decoration: none;
    display: block;

    &:link {
      color: $ci-very-dark-grey;
    }

    &:hover {
      color: $ci-very-dark-grey;
    }

    &:active {
      color: $ci-very-dark-grey;
    }

    &:visited {
      color: $ci-very-dark-grey;
    }
  }

  &__main-content {
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
  }

  &__thumb {
    @include border(2px, 0, $ci-grey);
    width: 120px;
    height: 120px;
    object-fit: cover;
  }

  &__button {
    margin: 0 !important;

    a {
      color: $ci-white-bg;
    }
  }
}

.Listing-attribute {
  display: flex;
  @include font-size("medium");
  margin: $small-rem $no-rem;

  &__label {
    @include font-weight("bold");
    margin-right: $even-rem;
  }

  &__value {
  }
}

/*HERO BANNER*/
.hero-swiper {
  width: 100%;
  height: 50vh;
  position: relative;
}

.hero-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-size: cover;
  background-position: center;
  color: $ci-darkblue;
  text-align: center;
  background: $ci-grey;
  border: 2px solid $ci-red;
}

.hero-content {
  // max-width: 600px;
  padding: $small-rem;
}

.hero-content h1 {
  font-size: $very-large-rem;
  margin-bottom: $even-rem;
}

.hero-content p {
  font-size: $medium-rem;
  margin-bottom: $very-large-rem;
}

.cta-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.cta-primary,
.cta-secondary {
  padding: 10px 20px;
  font-size: $even-rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cta-primary {
  background-color: #007bff;
  color: $ci-white-bg;
}

.cta-secondary {
  background-color: $ci-white-bg;
  color: #007bff;
  border: 2px solid #007bff;
}

.cta-primary:hover {
  background-color: #0056b3;
}

.cta-secondary:hover {
  background-color: #007bff;
  color: $ci-white-bg;
}
