@import "../../styles/setting.module";

.Auth {
  // border: 10px solid $ci-lightblue-bg;
  // padding: 1em;
}

.Authform_links {
  font-size: $even-rem;
}

.WelcomeHeader {
  font-size: $large-rem;
  text-align: center;
}

.LoginHeader {
  font-size: $medium-rem;
  font-weight: bold;
}

.Aboutus {
  margin-top: $large-rem;
  padding-top: $even-rem;
  border-top: 2px solid $ci-very-dark-grey;
}
