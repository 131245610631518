@import "../../styles/setting.module";

.Layout {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.Content {
  max-width: $screen-max-width;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.Content-large-top-margin {
  margin-top: 95px;
}

@media only screen and (min-width: $laptop-and-above-breakpoint) {
}
